import React from "react";
import Image from "gatsby-image";
import Layout from "../components/Layout";
import { useHeadshot } from "../hooks/useHeadshot";
import { useTheme } from "../components/ThemeContext";
import Container from "../components/Container";
import SeoHelper from "../components/SeoHelper";

export default function About() {
  const headshot = useHeadshot();
  const { theme } = useTheme();
  const { name, bio } = theme.author;

  return (
    <Layout>
      <SeoHelper
        title={`About ${theme.title} & ${name}`}
        description={`We're here to help you! Click to learn about ${theme.title} and why we started ${theme.url}.`}
      />
      <Container>
        <div className="flex flex-wrap py-4">
          <h1 className="w-full mb-4 text-lg font-bold md:text-3xl md:mb-6">About the Author</h1>
          <div className="w-full md:w-1/2 lg:w-1/4 md:pr-4 lg:pr-8">
            <Image alt={`about ${name}`} className="max-w-full" fluid={headshot.fluid} style={{ width: "380px" }} />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="my-2 text-lg font-bold">{name}</h3>
            <div className="wysiwig" dangerouslySetInnerHTML={{ __html: bio }} />
          </div>
        </div>
        <section className="w-full py-4 md:w-3/4">
          <h2 className="w-full mb-4 text-lg font-bold md:text-3xl md:mb-6">About Zey Ventures</h2>
          <p className="mb-4">
            <a
              className="text-primary hover:underline"
              href="https://zeyventures.com"
              title="Zey Ventures"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zey Ventures
            </a>{" "}
            is a publishing syndicate founded in 2020 by{" "}
            <a
              className="text-primary hover:underline"
              href="https://tylerzey.com"
              title="Tyler Zey"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tyler
            </a>{" "}
            and{" "}
            <a
              className="text-primary hover:underline"
              href="https://sophiazey.com"
              title="Sophia Zey"
              target="_blank" // eslint-disable-line
              rel="noopener"
            >
              Sophia Zey
            </a>{" "}
            with the goal of providing high quality content using the latest web technology for fast, instant access to reviews,
            blogs, and more educational content.
          </p>
          <p className="mb-4">
            Feedback? We would love to hear your ideas and suggestions for improvement. Please contact us through{" "}
            <a
              className="text-primary hover:underline"
              href="https://zeyventures.com"
              title="Zey Ventures"
              target="_blank" // eslint-disable-line
              rel="noopener"
            >
              Zey Ventures Website
            </a>{" "}
          </p>
        </section>
      </Container>
    </Layout>
  );
}
